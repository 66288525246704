<template>
  <v-dialog
    v-model="newTemplateGroupVisible"
    @click:outside="cancelNewTemplateGroup"
    :key="newTemplateGroupVisible"
    width="1000"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn :disabled="newBtnDisabled"  text v-bind="attrs" v-on="on" class="btn-s">{{$t('Add')}}</v-btn>
    </template>

    <v-card class="popup add_store_popup">
      <h3 class="page-title-bar">
        <i class="ico ico-template"></i>{{ $t('ADD TEMPLATE GROUP') }}
      </h3>
<v-row >
  <v-col cols="6">
    <section class="searchArea">
      <v-select
        v-model="searchtemplateTypelist"
        :items="tagTypes"
        item-text="name"
        class="form-select ml-2"
        outlined dense hide-details
        :placeholder="`${$t('Select template size')}`"
        style="padding-right:13px !important"
        :label="$t('Template Size')"
        @change="selectTemplateSearch"
      ></v-select>
      <!-- template color  -->
      <v-select
        v-model="searchtemplateColor"
        :items="tempColorType"
        item-text="name"
        class="form-select ml-2"
        outlined dense hide-details
        :placeholder="`${$t('Select template color')}`"
        style="padding-right:13px !important"
        :label="$t('Template Color')"
        @change="selectTemplateSearch"
      >
      <template  v-slot:selection="data">
        <div style="font-size: 12px;">
          <div v-if="data.item=='All'">{{data.item}}</div>
          <div v-if="data.item!='All'">
        <span style="color:#676565">{{ data.item.split("")[0] }}</span>
  <span  style="color:#d1c3c3">{{ data.item.split("")[1] }}</span>
  <span :style="data.item.split('')[2]==='R'?'color:red':'color:yellow'">{{ data.item.split("")[2] }}</span>
  <span  style="color:yellow">{{data.item.split("")[3] }}</span>
  <span  style="color:orange">{{ data.item.split("")[4] }}</span>
  <span  style="color:green">{{ data.item.split("")[5] }}</span>
  <span  style="color:blue">{{ data.item.split("")[6] }}</span>
  </div>
</div>
  </template>
          <template v-slot:item="data">
            <div  style="font-size: 12px;">
              <div v-if="data.item=='All'">{{data.item}}</div>
              <div v-if="data.item!='All'">
                <span style="color:#676565">{{ data.item.split("")[0] }}</span>
  <span  style="color:#d1c3c3">{{ data.item.split("")[1] }}</span>
  <span :style="data.item.split('')[2]==='R'?'color:red':'color:yellow'">{{ data.item.split("")[2] }}</span>
  <span  style="color:yellow">{{data.item.split("")[3] }}</span>
  <span  style="color:orange">{{ data.item.split("")[4] }}</span>
  <span  style="color:green">{{ data.item.split("")[5] }}</span>
  <span  style="color:blue">{{ data.item.split("")[6] }}</span>
              </div>

</div>
      </template>
    </v-select>
      <!-- end -->
      <v-text-field
        v-model="search"
        outlined
        dense
        hide-details
        :placeholder="`${$t('Input Type Name')}`"
        class="form-input"
        clearable
        :label="$t('Type Name')"
        @keydown="clearTabindex"
      >
      </v-text-field>
    </section>
      <v-data-table
       :headers="templateHeadersLeft"
       :items="templateTypeList"
       item-key="typeName"
       :hide-default-footer="true"
       height="250"
       class="tbl-type01 flat-type02 style03 grayline "
       :items-per-page="2000"
       single-select
       style="padding-left:10px;padding-top:30px;cursor:pointer"
       :search="search"
       @click:row="selectTemplateype"

      >
      <template slot="no-data">
        <p>
          {{ $t('No data available') }}
        </p>
      </template>
      <!-- template color -->
      <template v-slot:[`item.templateColor`]="{ item }">
        <span style="color:#676565">{{ item.templateColor.split("")[0] }}</span>
        <span  style="color:#d1c3c3">{{ item.templateColor.split("")[1] }}</span>
        <span :style="item.templateColor.split('')[2]==='R'?'color:red':'color:yellow'">{{ item.templateColor.split("")[2] }}</span>
        <span  style="color:yellow">{{ item.templateColor.split("")[3] }}</span>
        <span  style="color:orange">{{ item.templateColor.split("")[4] }}</span>
        <span  style="color:green">{{ item.templateColor.split("")[5] }}</span>
        <span  style="color:blue">{{ item.templateColor.split("")[6] }}</span>
      </template>
      <!-- end -->
    </v-data-table>
        </v-col>
         <v-col cols="1" style="">
          <v-btn
            @click="handleAddClick"
            text
            outlined
            style="height:248px;margin-top:100px;width:1px;"
            class="red"
            dark
            > {{ $t('Add') }}</v-btn
          >
        </v-col>
        <v-col cols="5" >
          <section class="searchArea">
          <v-text-field
            v-model="groupName"
            :placeholder="`${$t('Input the group name')}`"
            :label="$t('TEMPLATE GROUP NAME')"
            class="form-input"
            outlined
            dense
            hide-details
            clearable
             @keypress="handleKeyUp"
            @keydown="clearTabindex"
          >
          </v-text-field>
    </section>
      <v-data-table
          v-model="selectedGroupedTemplates"
          :headers="templateHeaders"
          :items="addTemplateTypeLIst"
          item-key="sno"
          :hide-default-footer="true"
          height="250"
          class="tbl-type01 flat-type02 style03 grayline "
          :items-per-page="2000"
          single-select
          style="padding-left:10px;padding-top:30px;"
      >

    <template slot="no-data">
        <p>
          {{ $t('No data available') }}
        </p>
      </template>
      <!-- template color -->
      <template v-slot:[`item.templateColor`]="{ item }">
        <span style="color:#676565">{{ item.templateColor.split("")[0] }}</span>
        <span  style="color:#d1c3c3">{{ item.templateColor.split("")[1] }}</span>
        <span :style="item.templateColor.split('')[2]==='R'?'color:red':'color:yellow'">{{ item.templateColor.split("")[2] }}</span>
        <span  style="color:yellow">{{ item.templateColor.split("")[3] }}</span>
        <span  style="color:orange">{{ item.templateColor.split("")[4] }}</span>
        <span  style="color:green">{{ item.templateColor.split("")[5] }}</span>
        <span  style="color:blue">{{ item.templateColor.split("")[6] }}</span>
      </template>
      <!-- end -->
      <template  v-slot:[`item.icon`]="{ item }">
       <v-icon  style="color:red" @click="deleteSelectedTemplate(item)"   medium>mdi-close-circle </v-icon>
      </template>

    </v-data-table>
        </v-col>
      </v-row>
      <v-card-actions class="d-flex justify-center">
        <v-btn
          @click="handleSaveClick"
          text icon
          class="btn"
        >{{ $t('Save') }}
        </v-btn>
        <v-btn
          @click="cancelNewTemplateGroup"
          class="btn"
          text
          icon
        >{{ $t('Cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import EventBus from '@/plugins/eventBus'
import commons from '@/plugins/commons'
import codes from '@/plugins/codes'

export default {
  props: ['user', 'tagTypes', 'tempColorType', 'newBtnDisabled', 'templateTypeList'],
  data () {
    return {
      // cloud 용 임시
      productionType: process.env.VUE_APP_PRODUCTION_TYPE,
      page: 1,
      groupName: null,
      selectedGroupedTemplates: [],
      selectedTemplatesByTag: [],
      newTemplateGroupVisible: false,
      groupedTemplates: [],
      templatesByTag: [],
      selectModelType: [],
      templateTypelist: '',
      selectedTemplateType: [],
      searchtemplateTypelist: 'All',
      searchtemplateColor: 'All',
      addTemplateTypeLIst: [],
      search: '',
      msg: {
        confirm: {
          emptyGroup: this.$t('The template list is empty. Go ahead?')
        }
      }
    }
  },
  computed: {

    templateHeaders () {
      return [
        { text: this.$t('TEMPLATE SIZE'), value: 'templateSize' },
        { text: this.$t('COLOR'), value: 'templateColor' },
        { text: this.$t('TYPE NAME'), value: 'typeName' },
        { text: '', value: 'icon' }

      ]
    },
    templateHeadersLeft () {
      return [
        { text: this.$t('TEMPLATE SIZE'), value: 'templateSize' },
        { text: this.$t('COLOR'), value: 'templateColor' },
        { text: this.$t('TYPE NAME'), value: 'typeName' }

      ]
    }

  },

  methods: {
    handleKeyUp (e) {
      commons.specialCharRest(e)
    },

    clearTabindex () {
      var elements = document.getElementsByClassName('v-icon')
      for (let i = 0; i < elements.length; i++) {
        elements[i].setAttribute('tabindex', '-1')
      }
    },
    selectTemplateSearch () {
      // emit template color
      this.$emit('getSearchData', { tempsize: this.searchtemplateTypelist, tempcolor: this.searchtemplateColor })
    },
    selectTemplateype (item, row) {
      row.select(true)
      this.selectedTemplateType = []
      this.selectedTemplateType.push(item)
    },
    cancelNewTemplateGroup () {
      this.resetNewTemplateGroup()
      this.newTemplateGroupVisible = !this.newTemplateGroupVisible
    },
    resetNewTemplateGroup () {
      this.groupName = null
      this.groupedTemplates = []
      this.templatesByTag = []
      this.selectedGroupedTemplates = []
      this.selectedTemplatesByTag = []
      this.searchtemplateTypelist = 'All'
      this.searchtemplateColor = 'All'
      this.$emit('getSearchData', { tempsize: this.searchtemplateTypelist, tempcolor: this.searchtemplateColor })
      this.search = ''
      this.addTemplateTypeLIst = []
    },
    getTemplatesByTag (tag) {
      const params = { company: this.user.company, width: tag.width, height: tag.height, size: 1000 }
      const config = { params: params }
      if (this.productionType === 'cloud') params.company = this.$store.state.auth.user.company
      this.$utils
        .callAxios(
          codes.requests.getTemplatesByTag.method,
          codes.requests.getTemplatesByTag.url,
          config
        )
        .then(res => {
          document.getElementsByClassName('v-data-table__wrapper')[3].scrollTop = 0
          this.templatesByTag = res.data.templateList
          this.selectedTemplatesByTag = []
        })
    },
    buildAddGroupBody () {
      const addGroupBody = {}
      addGroupBody.groupName = commons.isValidStr(this.groupName) ? this.groupName.trim() : this.groupName
      const templateNameList = this.addTemplateTypeLIst.map(gt => gt.typeName)
      addGroupBody.templateNameList = templateNameList
      return addGroupBody
    },
    handleSaveClick () {
      if (Array.isArray(this.selectedGroupedTemplates) && this.addTemplateTypeLIst.length < 1) {
        // 추가하려는 그룹에 포함될 template이 한 개도 없는경우 사용자 진행의사 확인.
        this.$emit('fireConfirm', this.msg.confirm.emptyGroup, this.addGroup)
      } else {
        this.addGroup()
      }
    },
    addGroup () {
      const addGroupBody = this.buildAddGroupBody()
      const isValidData = commons.isValidData(addGroupBody)
      if (!isValidData.result) {
        EventBus.$emit('messageAlert', this.$t(isValidData.msg))
        return
      }
      const params = { company: this.user.company }
      const config = { params: params }
      if (this.productionType === 'cloud') params.company = this.$store.state.auth.user.company
      this.$utils
        .callAxiosWithBody(
          codes.requests.addGroup.method,
          codes.requests.addGroup.url,
          addGroupBody,
          config
        )
        .then(res => {
          const result = res.data
          EventBus.$emit('messageAlert', this.$t(result.responseMessage))
          this.cancelNewTemplateGroup()
          this.$emit('resetGroups')
        })
        .catch((error) => {
          EventBus.$emit('messageAlert', this.$t('Failed to add a new template group.'))
          this.cancelNewTemplateGroup()
          console.log(error)
        })
    },
    handleAddClick () {
      this.groupTemplates()
    },

    groupTemplates () {
      const templates = this.selectedTemplateType
      // Remove redundant templates beforehand.
      const template = templates.filter(template => {
        for (const gt of this.addTemplateTypeLIst) {
          if (gt.templateSize === template.templateSize && gt.templateColor === template.templateColor) {
            EventBus.$emit('messageAlert', this.$t('Same template size is already selected.'))
            return false
          }
        }
        return true
      })
      if (template.length !== 0) {
        this.addTemplateTypeLIst.push({
          typeName: template[0].typeName,
          templateSize: template[0].templateSize,
          templateColor: template[0].templateColor,
          icon: true
        })
      }
      this.selectedTemplatesByTag = []
    },
    deleteSelectedTemplate (item) {
      var removeIndex = this.addTemplateTypeLIst.map(function (item) { return item.typeName }).indexOf(item.typeName)
      this.addTemplateTypeLIst.splice(removeIndex, 1)
    }
  }
}
</script>

<style scoped>
.searchArea{
  align-items: normal !important;
}
::v-deep .tbl-type01.style03
th {
    background: #ececec !important;
}
::v-deep .type3{
    border: 1px solid lightgray;
}
::v-deep tr.v-data-table__selected {
    background:  lightgray !important;
  }
  ::v-deep .form-select.v-text-field--outlined .v-select__selections{
  display: flex !important;
}
</style>
