var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "page-title-bar" }, [
        _c("i", { staticClass: "ico ico-template" }),
        _vm._v(_vm._s(_vm.$t("Template Grouping")))
      ]),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "8" } }, [
            _c(
              "div",
              { staticClass: "searchArea" },
              [
                _c("v-text-field", {
                  staticClass: "form-input",
                  attrs: {
                    label: _vm.$t("Group List"),
                    placeholder: _vm.$t("Input group name"),
                    outlined: "",
                    dense: "",
                    "hide-details": "",
                    clearable: ""
                  },
                  on: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.searchGroups($event)
                    },
                    "click:clear": _vm.resetsearch
                  },
                  model: {
                    value: _vm.searchBy.groupName,
                    callback: function($$v) {
                      _vm.$set(_vm.searchBy, "groupName", $$v)
                    },
                    expression: "searchBy.groupName"
                  }
                }),
                _c(
                  "v-btn",
                  {
                    staticClass: "btn type-search ml-3",
                    attrs: { text: "" },
                    on: { click: _vm.searchGroups }
                  },
                  [_vm._v(_vm._s(_vm.$t("Search")))]
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-5" },
        [
          _c(
            "v-col",
            { attrs: { cols: "5" } },
            [
              _c(
                "v-data-table",
                {
                  staticClass: "tbl-type01 flat-type02 style03 grayline type3",
                  attrs: {
                    headers: _vm.groupHeaders,
                    items: _vm.groups,
                    "item-key": "groupName",
                    options: _vm.groupOptions,
                    "show-select": "",
                    "hide-default-footer": true,
                    height: "300",
                    "items-per-page": 2000
                  },
                  on: {
                    "click:row": _vm.showGroupDetail,
                    "update:options": function($event) {
                      _vm.groupOptions = $event
                    }
                  },
                  model: {
                    value: _vm.selectedGroups,
                    callback: function($$v) {
                      _vm.selectedGroups = $$v
                    },
                    expression: "selectedGroups"
                  }
                },
                [
                  _c("template", { slot: "no-data" }, [
                    _c("p", [
                      _vm._v(" " + _vm._s(_vm.$t("No data available")) + " ")
                    ])
                  ])
                ],
                2
              ),
              _c("div", { staticClass: "table-options" }, [
                _c(
                  "div",
                  [
                    _c("new-template-group", {
                      attrs: {
                        newBtnDisabled: _vm.newBtnDisabled,
                        user: _vm.user,
                        tagTypes: _vm.tagTypes,
                        tempColorType: _vm.tempColorType,
                        templateTypeList: _vm.templateTypeList
                      },
                      on: {
                        resetGroups: _vm.resetGroups,
                        fireConfirm: _vm.confirm,
                        getSearchData: function($event) {
                          return _vm.getSearchData($event)
                        }
                      }
                    }),
                    _c("edit-template-group", {
                      attrs: {
                        editDisabled: _vm.editBtnDisabled,
                        user: _vm.user,
                        tagTypes: _vm.tagTypes,
                        tempColorType: _vm.tempColorType,
                        group: _vm.selectedGroup,
                        templates: _vm.templates,
                        templateTypeList: _vm.templateTypeList,
                        selectedTemplateList: _vm.selectedTemplateList
                      },
                      on: {
                        resetGroups: _vm.resetGroups,
                        refreshTemplates: _vm.refreshTemplates,
                        getSearchData: function($event) {
                          return _vm.getSearchData($event)
                        }
                      }
                    }),
                    _c(
                      "v-btn",
                      {
                        staticClass: "btn-s ml-2",
                        attrs: { disabled: _vm.deleteBtnDisabled, text: "" },
                        on: { click: _vm.deleteGroups }
                      },
                      [_vm._v(_vm._s(_vm.$t("Delete")))]
                    )
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c("v-col", { attrs: { cols: "7" } }, [
            _c("div", { staticClass: "customInfo" }, [
              _c(
                "div",
                {
                  staticClass: "info-preview",
                  staticStyle: { width: "680px" }
                },
                [
                  _c("h4", { staticClass: "tit" }, [
                    _vm._v(_vm._s(_vm.$t("TEMPLATE TYPE OF SELECTED GROUP")))
                  ]),
                  _c(
                    "div",
                    {},
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "5" } },
                            [
                              _c(
                                "v-data-table",
                                {
                                  staticClass:
                                    "tbl-type01 flat-type02 style03 grayline type3",
                                  staticStyle: { cursor: "pointer" },
                                  attrs: {
                                    headers: _vm.templateHeaders,
                                    items: _vm.templates,
                                    "item-key": "name",
                                    options: _vm.templateOptions,
                                    "hide-default-footer": true,
                                    "fixed-header": "",
                                    height: "235",
                                    "items-per-page": 2000,
                                    "single-select": ""
                                  },
                                  on: {
                                    "update:options": function($event) {
                                      _vm.templateOptions = $event
                                    },
                                    "click:row": _vm.getImagePreview
                                  },
                                  model: {
                                    value: _vm.selectedTemplates,
                                    callback: function($$v) {
                                      _vm.selectedTemplates = $$v
                                    },
                                    expression: "selectedTemplates"
                                  }
                                },
                                [
                                  _c("template", { slot: "no-data" }, [
                                    _c("div", [_vm._v(_vm._s(_vm.noDataMsg))])
                                  ])
                                ],
                                2
                              )
                            ],
                            1
                          ),
                          _c("v-col", { attrs: { cols: "7" } }, [
                            _c("div", { staticClass: "customInfo" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "info-preview",
                                  staticStyle: { width: "480px" }
                                },
                                [
                                  _c("div", { staticClass: "preview" }, [
                                    _c("img", {
                                      staticClass: "img-label",
                                      attrs: { src: _vm.imageContent }
                                    })
                                  ])
                                ]
                              )
                            ])
                          ]),
                          _c(
                            "v-col",
                            { staticStyle: { "margin-top": "-20px" } },
                            [
                              _c("div", [_vm._v(_vm._s(_vm.$t("Store")))]),
                              _c("v-autocomplete", {
                                attrs: {
                                  outlined: "",
                                  "prepend-inner-icon": "mdi-magnify",
                                  dense: "",
                                  "hide-details": "",
                                  items: _vm.getStoreDetails
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.getarticleList(
                                      _vm.selectedStore,
                                      1
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.selectedStore,
                                  callback: function($$v) {
                                    _vm.selectedStore = $$v
                                  },
                                  expression: "selectedStore"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticStyle: { "margin-top": "-20px" },
                              attrs: { cols: "12", sm: "6" }
                            },
                            [
                              _c("div", [_vm._v(_vm._s(_vm.$t("Product")))]),
                              _c("v-text-field", {
                                staticClass: "form-input  in-btn",
                                staticStyle: {
                                  "margin-bottom": "10px",
                                  "padding-top": "0px"
                                },
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  "hide-details": "",
                                  placeholder: "" + _vm.$t("Input Product ID"),
                                  clearable: ""
                                },
                                on: { input: _vm.searchProductValue },
                                scopedSlots: _vm._u([
                                  {
                                    key: "append",
                                    fn: function() {
                                      return [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: { text: "" },
                                            on: { click: _vm.handleSearch }
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: require("@/assets/img/btn-search.jpg"),
                                                alt: ""
                                              }
                                            })
                                          ]
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ]),
                                model: {
                                  value: _vm.searchProduct,
                                  callback: function($$v) {
                                    _vm.searchProduct = $$v
                                  },
                                  expression: "searchProduct"
                                }
                              }),
                              _c("v-autocomplete", {
                                attrs: {
                                  "return-object": "",
                                  autocomplete: "off",
                                  outlined: "",
                                  "prepend-inner-icon": "mdi-magnify",
                                  dense: "",
                                  "hide-details": "",
                                  placeholder: "" + _vm.$t("Input Product ID"),
                                  "item-text": "articleId",
                                  "item-value": "articleId",
                                  items: _vm.getarticleListData,
                                  "no-data-text":
                                    "" + _vm.$t("No data available")
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.getImagePreview(
                                      _vm.selectedProduct
                                    )
                                  }
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "append-item",
                                    fn: function() {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "intersect",
                                                rawName: "v-intersect",
                                                value: _vm.onIntersect,
                                                expression: "onIntersect"
                                              }
                                            ],
                                            staticClass: "pa-4 teal--text"
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "loadingBar" },
                                              [
                                                !_vm.moreItemDisable
                                                  ? _c("v-progress-circular", {
                                                      attrs: {
                                                        indeterminate: "",
                                                        size: 50,
                                                        width: 7,
                                                        color: "#ddd"
                                                      }
                                                    })
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ]),
                                model: {
                                  value: _vm.selectedProduct,
                                  callback: function($$v) {
                                    _vm.selectedProduct = $$v
                                  },
                                  expression: "selectedProduct"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", width: "500" },
              model: {
                value: _vm.confirmBox.visible,
                callback: function($$v) {
                  _vm.$set(_vm.confirmBox, "visible", $$v)
                },
                expression: "confirmBox.visible"
              }
            },
            [
              _c(
                "v-card",
                {
                  staticClass: "popup add_store_popup",
                  attrs: { align: "center", justify: "center" }
                },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", [
                        _c("span", {
                          domProps: { textContent: _vm._s(_vm.confirmBox.msg) }
                        })
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    { staticClass: "d-flex justify-center" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn",
                          attrs: { text: "", icon: "" },
                          on: {
                            click: function($event) {
                              return _vm.handleClickConfirm(true)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("Yes")))]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn",
                          attrs: { text: "", icon: "" },
                          on: {
                            click: function($event) {
                              return _vm.handleClickConfirm(false)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("No")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }